import type { ApplicationConfig } from '@angular/core';
import { provideExperimentalZonelessChangeDetection } from '@angular/core';
import {
    AppFeature,
    DealerFeature,
    FootnotesFeature,
    LoginFeature,
    provideCore,
    TrackingFeature,
} from '@hyundai/ng-common-lib';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { environment } from '../environments/environment';
import { createRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideExperimentalZonelessChangeDetection(),
        provideCore({
            routes: createRoutes(),
            dealcoreApiConfig: {
                baseUrl: environment.dealcoreBaseUrl,
            },
            cloudinaryBaseUrl: environment.cloudinaryBaseUrl,
            showroomScriptUrl: environment.showroomScriptUrl,
            preloading: environment.preloading,
        }),
        provideState(AppFeature.state),
        provideState(LoginFeature.state),
        provideState(DealerFeature.state),
        provideState(FootnotesFeature.state),
        provideState(TrackingFeature.state),
        provideEffects([
            AppFeature.effects,
            LoginFeature.effects,
            DealerFeature.effects,
            FootnotesFeature.effects,
            TrackingFeature.effects,
        ]),
    ],
};

import { type ActivatedRouteSnapshot, type Routes } from '@angular/router';
import { DealerFeature, ngrxLoaderGuard } from '@hyundai/ng-common-lib';
import {
    AboutUsSlug,
    BusinessCustomerSlug,
    CampaignsSlug,
    LegalSlugs,
    ModelSlugs,
    PromiseSlug,
    ServicesSlug,
    ShopSlug,
    WarrantySlug,
} from '@hyundai/shared-lib';
import { PartnerWebComponent } from './components/partner-web/partner-web.component';
import { dealerGuard } from './guards/partnerweb.guard';
import type { AboutUsPageData } from './pages/about-us-page/about-us-page.routes';

const children: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/home-page/home.routes'),
    },
    {
        path: '404',
        loadChildren: () => import('./pages/not-found-page/not-found-page.routes'),
        title: '404 - Not Found',
    },
    {
        path: ServicesSlug,
        loadChildren: () => import('./pages/service-pages/services-pages.routes'),
        canActivate: [dealerGuard((dealer) => dealer.hasService)],
    },
    {
        path: AboutUsSlug,
        loadChildren: () => import('./pages/about-us-page/about-us-page.routes'),
    },
    {
        path: ShopSlug,
        loadChildren: () => import('./pages/shop-page/shop.routes'),
        canActivate: [dealerGuard((dealer) => dealer.partsStore)],
    },
    {
        path: CampaignsSlug.list,
        loadChildren: () => import('./pages/campaign-pages/campaign-page.routes'),
    },
    {
        path: ModelSlugs,
        loadChildren: () => import('./pages/model-pages/model.routes'),
        canActivate: [dealerGuard((dealer) => dealer.hasSales)],
    },
    {
        path: WarrantySlug,
        loadChildren: () => import('./pages/warranty-page/warranty-page.routes'),
    },
    {
        path: PromiseSlug,
        loadChildren: () => import('./pages/promise-page/promise-page.routes'),
        canActivate: [dealerGuard((dealer) => dealer.hPromise)],
    },
    {
        path: BusinessCustomerSlug,
        loadChildren: () => import('./pages/business-customer-page/business-customer-page.routes'),
        canActivate: [dealerGuard((dealer) => !!(dealer.hasSales && dealer.pages.businessCustomers))],
    },
    {
        path: LegalSlugs.imprint,
        loadChildren: () => import('./pages/legal-pages/imprint-page/imprint-page.routes'),
    },
    {
        path: LegalSlugs.legal,
        loadChildren: () => import('./pages/legal-pages/legal-notices-page/legal-notices-page.routes'),
    },
    {
        path: LegalSlugs.privacy,
        loadChildren: () => import('./pages/legal-pages/privacy-page/privacy-page.routes'),
        title: 'Datenschutz',
    },
    {
        path: ':subDealerSlug',
        canActivate: [
            dealerGuard((dealer, route) =>
                dealer.subDealers.some((dealer) => dealer.slug === route.params['subDealerSlug']),
            ),
        ],
        data: {
            isSubDealerAware: true,
        } satisfies AboutUsPageData,
        loadChildren: () => import('./pages/about-us-page/about-us-page.routes'),
    },
    {
        path: '**',
        redirectTo: '404',
        pathMatch: 'full' as const,
    },
];

// eslint-disable-next-line max-lines-per-function
export function createRoutes(): Routes {
    return [
        {
            path: '',
            loadChildren: () => import('./pages/list-page/list.routes'),
        },
        {
            path: 'login',
            loadComponent: () => import('./components/dealer-login/dealer-login.component'),
            loadChildren: () => import('./pages/login-page/login.routes'),
            title: 'Login',
        },
        {
            path: ':slug',
            component: PartnerWebComponent,
            canActivate: [
                ngrxLoaderGuard({
                    actionToDispatch: (route: ActivatedRouteSnapshot) =>
                        DealerFeature.actions.loadDealerBySlug({ slug: route.params['slug'] }),
                    waitUntilLoadedSelector: DealerFeature.state.selectIsLoadingDealer,
                    dataToCheckSelector: DealerFeature.state.selectLoadedDealer,
                    redirectOnFailUrl: 'https://www.hyundai.com/de/de.html',
                }),
            ],
            children,
        },
    ];
}

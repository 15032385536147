import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppFeature, EventTrackingService } from '@hyundai/ng-common-lib';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';
import { FooterPwComponent } from './components/footer-pw/footer-pw.component';
import { NavBarComponent } from './components/nav/nav-bar/nav-bar.component';

@Component({
    standalone: true,
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FooterPwComponent, RouterOutlet, NavBarComponent],
})
export class AppComponent {
    store = inject(Store);
    protected readonly trackingService = inject(EventTrackingService);

    constructor() {
        inject(ViewportScroller).setOffset([0, 50]);

        this.store.dispatch(AppFeature.actions.initPage({ environment: environment.trackingTerm }));
        this.trackingService.init();
    }
}
